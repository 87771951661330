.titlebar-layout__titlebar-buttons {
    line-height: 1.875rem;
}

.titlebar-layout__container {
    overflow: auto;
    padding-left: 3rem;
    padding-right: 3rem;
}

.binding-calculation-editor__buttons {
    text-align: center;
}

.binding-calculation-editor__buttons .btn-lg {
    height: 3.75rem;
    margin: 0.875rem 1.875rem 1.5rem 1.875rem;
}

.editview .sample-error-message {
    color: orangered;
    margin-top: 0.3em;
    max-width: 480px
}

.editview div.btn-group ul.dropdown-menu {
    display: inline;
    border: solid thin red;
}

titlebar-layout__titlebar-container h1 {
    margin-bottom: -3rem;
}

.editview.centerview {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    min-width: min-content;
}

.editview table {
    table-layout: fixed;
}

.editview thead {
    height: 2.5rem;
    background-color: #5b6770;
    display: table-row-group;
}

.editview thead th {
    color: #fff;
    border-right: 0.0625rem solid #fff;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
}

.editview thead th:first-child {
    border-left: 0.0625rem solid #5b6770;
}

.editview thead th {
    position: sticky;
    top: 50px;
    z-index: 997;
    background-color: #5b6770;
}

.editview thead th:first-child {
    position: sticky;
    z-index: 999;
    background-color: #5b6770;
    left: 0px;
    top: 50px;
    border-right: 0.0625rem solid #b1b8bd;
    box-shadow: -1px 0px 0px 0px #5b6770, 1px 0px 0px 0px #fff;
}

.editview tbody td:first-child {
    position: sticky;
    left: 0px;
    z-index: 998;
    background-color: #CDCDCD;
    border-right: 0.0625rem solid #b1b8bd;
    /* outline: 1px solid #e9ecef;
    outline-offset: -1px; */
    box-shadow: -1px 0px 0px 0px #b1b8bd, 1px 0px 0px 0px #fff;
}

.editview thead th:last-child {
    border-right-color: #5b6770;
}

.editview tbody tr {
    border-top: 1px solid #b1b8bd;
}

.editview tbody tr:last-child {
    border-bottom: 1px solid #b1b8bd;
}

.editview tbody tr:nth-child(even) td {
	background-color: #EDEDED;
}

.editview tbody tr:nth-child(odd) td {
	background-color: white;
}

.editview td {
    border-right: 0.0625rem solid #b1b8bd;
    color: #5b6770;
    line-height: 0.875rem;
    max-width: 8rem;
    min-width: 8rem;
    width: 8rem;
    overflow: hidden;
    word-wrap:break-word;
    padding: 0.8125rem 0.9375rem;
    text-align: right;
}

.editview td:first-child {
    max-width: 14rem;
    min-width: 14rem;
    width: 14rem;
}

.editview td.sample-warning {
    text-align: left;
}

.editview td.text-align-left {
    text-align: left;
}

.editview .edit-table td {
    max-width: 19rem;
    min-width: 19rem;
    width: 19rem;
    color: black;
}

.editview td:first-child {
    border-left: 0.0625rem solid #b1b8bd;
}

.editview .units-container {
    display: flex;
    flex-direction: row;
}

.editview label {
    color: inherit;
    font: inherit;
    margin-left: 0.625rem;
}

.editview .units-container input[type="text"] {
	display: inline;
    /* CSS validation complains about these next two lines being ignored but they are used */
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;
    text-align: right;
}

.editview select {
	display: inline;
    /* CSS validation complains about these next two lines being ignored but they are used */
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;
}

.editview textarea {
	display: inline;
    /* CSS validation complains about these next two lines being ignored but they are used */
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;
    text-align: left;
}

.editview select {
    width: 100%;
}

.editview table .btn {
    margin-bottom: -0.375rem;
    margin-top: -0.625rem;
}

.editview ul {
    padding-left: 1.25rem;
    margin-bottom: 0;
}

.editview li {
    margin-bottom: 0.625rem;
}

.editview li:last-child {
    margin-bottom: 0;
}

.editview .separator {
    margin: 2.5rem;
}

.editview #mismatched-type-view h4 {
    padding-top: 0rem;
}

.editview .version-information {
    margin-top: 0.3rem;
    font-size: 90%;
}

.editview .validation-errors {
    margin-top: 0.6rem;
    color: orangered
}

.editview .validation-warnings {
    margin-top: 0.6rem;
    color: black
}

.editview .information-messages {
    margin-top: 0.9rem;
    color: black
}

.editview .units-container .validation-errors {
    margin-top: 0;
}

.sample-setup-editor-header, .sample-setup-error-list, .binding-calculation-editor__buttons, .instructions-locator {
    position: sticky;
    left: 2rem;
    width: 90vw;
}

.sample-setup-editor-main, #formSampleCalc {
    width: fit-content;
}

.btn.btn-primary.sample-action.ht {
    padding: 0.2rem 0.4rem
}

@media print {
    :host {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .titlebar-layout__container {
        overflow: hidden;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .editview thead th {
        border: 0.0625rem solid black;
    }

    .editview .separator {
        display: none;
    }

    /* narrow columns for printing */

    .editview td {
        border-right: 0.0625rem solid #b1b8bd;
        line-height: 0.875rem;
        padding: 0.5rem 0.6rem;
        max-width: 6rem;
        min-width: 6rem;
        width: 6rem;
    }

    .editview td:first-child {
        max-width: 9rem;
        min-width: 9rem;
        width: 9rem;
    }
}

@media print {
    body {
        overflow: visible !important;
    }
    #poolingCalculatorPrintable .modal {
        position: relative !important;
    }
    #poolingCalculatorPrintable .modal-backdrop {
        display: none
    }
    #poolingCalculatorPrintable .modal-dialog,
    #poolingCalculatorPrintable .modal-content,
    #poolingCalculatorPrintable .modal-body {
        height: auto !important;
        overflow: visible !important;
        max-width: 100%;
        width: 100%;
    }
    #poolingCalculatorPrintable .modal-dialog,
    #poolingCalculatorPrintable .modal-content {
        border: none
    }
    #poolingCalculatorPrintable #pooling-calculator-table-grid.ag-theme-alpine {
        height: auto !important;
    }
}
