/* eslint-disable */

pb-tree-node {
  white-space: nowrap;
}

span.icon-node {
  font-size: 18px;
  margin-right: 5px;
  cursor: pointer;
}

span.icon-node {
  margin-right: 5px;
  color: rgba(248, 148, 6, 0.53);
}

.tree-view.tv-root {
  background-color: white;
  border: 1px solid #ececec;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
}

.tree-view-wrapper {
  height: 350px;
  overflow: auto;
  border: 2px solid #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

.tree-view {
  list-style-type: none;
}

.tree-view li {
  margin-left: -1.5rem;
}

.tree-view > div {
  background-color: #fff;
}

.tree-view > div:first {
  padding-top: 10px;
  overflow: auto;
}

span.icon-leaf {
  font-size: 16px;
  cursor: pointer;
}

span.icon-leaf {
  margin-right: 5px;
  color: rgba(251, 198, 123, 0.61);
}

.selected-leaf-text {
  background-color: yellow;
  width: fit-content;
}

.path-error {
  color: red;
  font-size: 1.1em;
  padding: 5;
}

.tree-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-bottom: 20px;
  height: 100%;
  overflow: hidden;
}

.selected-value {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 48px;
  overflow-x: scroll;
  overflow-y: hidden;
}
