// PacBio Corp Color Variables

//---- These colors come from the 2021 rebranding (SL-7438) and should eventually be renamed to remove "2021"

// Colors found here on page 20: 
// https://pacbioca-my.sharepoint.com/personal/jmellon_pacificbiosciences_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fjmellon%5Fpacificbiosciences%5Fcom%2FDocuments%2FMicrosoft%20Teams%20Chat%20Files%2F102%2D193%2D688%5FPacBio%5Fstandards%5Fmanual%204%2Epdf&parent=%2Fpersonal%2Fjmellon%5Fpacificbiosciences%5Fcom%2FDocuments%2FMicrosoft%20Teams%20Chat%20Files&ga=1
$pb-2021-magenta: #Df1995;
$pb-2021-blue: #1583C6;
$pb-2021-darker-blue:#005596;
$pb-2021-even-darker-blue:#14406B;
$pb-2021-hover-blue:#0A6CAE;
$pb-2021-dark-magenta: #A20067;
$pb-2021-light-teal: #00B8C4;
$pb-2021-bright-purple: #5F249F;
$pb-2021-light-purple: #8D6DB0;

// ad hoc color. version of magenta light enough to read against blue header
$pb-light-magenta: #dd89be;

// --- These colors come from the original 5am design and should eventually be removed

/// Corp Primary

$pb-p-red:                  #B50937;
$pb-p-black:                #000000;
$pb-p-gray:                 #706F73;

/// Corp Secondary

$pb-s-purple:               #553176;
$pb-s-dblue:                #233652;
$pb-s-bonblue:              #008EB9;
$pb-s-green:                #67C18C;
$pb-s-chartreuse:           #CADB44;

/// Corp Tertiary

$pb-t-rosebud:              #893364;
$pb-t-hipblue:              #518691;
$pb-t-sage:                 #909974;
$pb-t-gamboge:              #D19412;