.alarms {
  cursor: pointer;
  color: white;
  white-space: nowrap;
}

.fatal-alarm {
  font-weight: bold;
  color: purple;
}

.critical-alarm {
  color: purple;
}

.error-alarm {
  color: red;
}

.warning-alarm {
  color: orange;
}