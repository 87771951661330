/* eslint-disable */

.login-page {
    width: 300px;

    h2 {
        text-align: center;
    }

    .button-wrapper {
        text-align: center;
    }
}

.button-style {
    left: calc(50% - calc(1rem / 2));
}

.span-style {
    margin-right: 1rem;
}

#moduleDropdown {
    font-family: var(--font-family-sans-serif);
    font-size: 1.1rem;
}

.table-search {
    height: 24px;
}

.txt-table-title {
    display: flex;
    flex: 1;
    text-align: center;
    padding: 0px 24px;
}

.btn-cart {
    font-size: 16px;
    margin-top: -10px;

    .badge {
        font-size: 14px;
        position: absolute;
        top: 16px;
        left: 22px;
        background: var(--primary);
        color: #fff;
        border: 1px #fff solid;
    }

    &:hover {
        .badge {
            background: #fff;
            color: var(--primary);
            border: 1px var(--primary) solid;
        }
    }
}

.analysis-selector-wrapper {
    div[role=group] {
        position: absolute;
        top: 25px;
    }
}

.data-selector-wrapper {
    position: relative;
}

.run-selector-wrapper {
    position: relative;
}

#inputSuffix {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.analysis-selector-wrapper {
    button:hover {
        color: #667386;
        background-color: #fff;
    }
}

.pb-badge-text {
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
}

.pb-modal-header {
    font-weight:600;
    font-size:1.5rem
}

.pb-modal-header-smaller {
    font-weight:400;
    font-size:1.2rem
}

/* make ag-grid table values selectable */
.ag-root.ag-unselectable {
    -webkit-user-select: text !important;
    user-select: initial !important;
}

.ag-theme-alpine .active .ag-icon.ag-icon-filter  {
    background-color: paleturquoise;
 }
