/* eslint-disable */

/* DOCUMENT INFORMATION
	- Document: SMRT Link UI Refresh
	- Version:  1.0.0
	- Client:   The Fantastic SMRT Link Development Team at PacBio
	- Author:   Chris Rogers of 5AM Solutions, Inc.
*/

/* CSS DIRECTORY
    0.  =FPO (For Prototype Only)
    1.  =PRE-IMPORT VARIABLES
            1A. =UI Refresh Theme
            1B. =BS Variables
            1C. =BS Typography
            1E. =Data Visualization: 21 Colors of Optimal Contrast
    2.  =IMPORTS & POST-IMPORT VARIABLES
    3.  =FUNCTIONS & MIXINS
    4.  =GLOBAL
            4A. =Scaffolding
            4B. =Custom BS Styles (Nav, Buttons, Forms, Tables, etc)
            4C. =Body Theme
	5.  =LAYOUT
    6.  =SVG
            6A. =Fills & Strokes
            6B. =Custom Icons
            6C. =Animations
    7.  =COMPONENTS
    8.  =MODULES
            8A. =Sample Setup
            8B. =Run Design
            8C. =Run QC
            8D. =SMRT Analysis
            8E. =Data Management
*/

/* ===VARIABLES=== */

// UI Refresh Theme

@import "colors-pacbio-brand";

$smrtl-ssetup:                  $pb-s-chartreuse;
$smrtl-rdesign:                 $pb-s-green;
$smrtl-rqc:                     $pb-t-hipblue;
$smrtl-dmgmt:                   $pb-s-purple;
$smrtl-analysis:                #2CA7B5;

$smrtl-primary:                 $pb-2021-blue;
$smrtl-secondary:               #667386;
$smrtl-light:                   #F6F6F6;
$smrtl-dark:                    $pb-s-dblue;

$smrtl-accent-light:            #E8EAED;
$smrtl-accent-gray:             #D3D7DC;
$smrtl-accent-dark:             #495057;
$smrtl-paleblugray:             #EFF1F5;
$smrtl-lgray:                   #DEE2E6;
$smrtl-gray:                    #6C757D;
$smrtl-black:                   #212529;
$smrtl-richblack:               #050606;

// BS Variables

$body-bg:                       #FFF;
$body-color:                    $smrtl-black;
$link-color:                    #09528a;

$breadcrumb-bg:                 $smrtl-accent-light;

$dropdown-link-hover-bg:        $smrtl-accent-light;

$nav-pills-link-active-bg:      $smrtl-dark;

$input-color:                   $smrtl-richblack;
$input-border-color:            $smrtl-accent-gray;
$input-hover-border-color:      darken($input-border-color, 20%);
$input-placeholder-color:       $smrtl-accent-dark;

$table-bg:                      #FFF;
$table-accent-bg:               $smrtl-light;
$table-active-bg:               $smrtl-accent-light;
$table-dark-bg:                 $smrtl-dark;
$table-hover-bg:                rgba($smrtl-accent-light, .075);

$tooltip-bg:                    $smrtl-dark;

$popover-border-color:          rgba($smrtl-primary, .8);
$popover-header-bg:             $smrtl-dark;
$popover-arrow-color:           $popover-header-bg;
$popover-header-color:          #FFF;

$spacer:                        1rem;
$navbar-padding-y:              calc(var($spacer) / 3);
$navbar-brand-padding-y:        0;

$theme-colors: (
    "primary":          $smrtl-primary,
    "secondary":        $smrtl-secondary,
    "light":            $smrtl-light,
    "dark":             $smrtl-dark,
    "analysis":         $smrtl-analysis,
    "dmgmt":            $smrtl-dmgmt
);

// BS Typography

$font-size-base:        1rem;
$font-size-lg:          ($font-size-base * 1.1);
$font-size-sm:          ($font-size-base * .833);
$font-size-xs:          ($font-size-base * .694);

$h1-font-size:          $font-size-base * 1.44;
$h2-font-size:          $font-size-base * 1.3;
$h3-font-size:          $font-size-base * 1.2;
$h4-font-size:          $font-size-base * 1.1;
$h5-font-size:          $font-size-base * 1;
$h6-font-size:          $font-size-base * 1;

$display1-size:         $font-size-base * 7.5;
$display2-size:         $font-size-base * 4;
$display3-size:         $font-size-base * 3.75;
$display4-size:         $font-size-base * 2.074;

// Data Visualization: 21 Colors of Optimal Contrast

@import "colors-data-viz";

/* ===IMPORTS & POST-IMPORT VARIABLES=== */

@import "node_modules/bootstrap/scss/bootstrap";
@import "dev-team";

// Post-Import Variables

$fixed-nav-height:                      3.5rem;

$ag-module-main-height:                 57vh;
$ag-module-main-padding-x:              1rem;

/* ===FUNCTIONS & MIXINS=== */

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin widthRem($size) {
  width: $size; //Fallback in px
  width: calculateRem($size);
}

@mixin mwRem($size) {
  max-width: $size; //Fallback in px
  max-width: calculateRem($size);
}

@mixin heightRem($size) {
  height: $size; //Fallback in px
  height: calculateRem($size);
}

@mixin mhRem($size) {
  max-height: $size; //Fallback in px
  max-height: calculateRem($size);
}

@mixin placeholder {
    &.placeholder                { @content }
    &::-webkit-input-placeholder { @content }
    &:-moz-placeholder           { @content }
    &::-moz-placeholder          { @content }
    &:-ms-input-placeholder      { @content }
}

/* ===GLOBAL=== */

// Scaffolding

.main {
    padding-top: $fixed-nav-height;
    @include media-breakpoint-up(xl) { margin: {right:1rem;left:1rem;}}
    @media screen and (min-width: 100em) { margin: {right:3rem;left:3rem;}}
    @media screen and (min-width: 120em) { margin: {right:5rem;left:5rem;}}
}

.navbar.navbar-expand {
    .container-nav { max-width: 100%; padding: {left: 15px;right: 15px;}}
    @media screen and (min-width: 100em) { padding: {right:3rem;left:3rem;}}
    @media screen and (min-width: 120em) { padding: {right:5rem;left:5rem;}}
}

.container-fluid {
    @media screen and (max-width: 1041px) { min-width: 1040px; overflow-y: scroll;}
}

.container-main {
    height: 100%;
    min-height: 768px;
    padding-bottom: 1rem;
}

.container-nav, .container-main {
    @media screen and (min-width: 100em) { padding: {right:3rem;left:3rem;}}
    @media screen and (min-width: 120em) { padding: {right:5rem;left:5rem;}}
}

.container-welcome { height: 90vh; padding-top: 20vh;}

.inner-container { margin: {left: 2rem;right: 2rem;}}

.col-ag-module-main, .col-ag-module-main-paging {
    padding: {left: $ag-module-main-padding-x;right: $ag-module-main-padding-x;}
}

.col-ag-module-main {
    height: $ag-module-main-height;
    min-height: 30rem;
}

.col-ag-min-height { min-height: 300px;}

.full-width-banner {
    background-color: $pb-2021-dark-magenta;
    width: 100vw;
    // this negative margin offsets the "main" class' margin of 3rem
    margin-left: -3rem
}
.run-qc-table {
    height: $ag-module-main-height;
    min-height: 30rem;
}

.mh-75 { max-height: 75vh;}

.vh-50 { height: 50vh;}

// Custom BS Styles (Nav, Buttons, Forms, Tables, etc)

h1, .h1, h2, .h2, h3, .h3 {
    font-family: 'Merriweather Sans', $font-family-sans-serif;
}

.font-size-lg { font-size: $font-size-lg;}

.font-weight-sbold { font-weight: 500;}

.h-subhead { color: $smrtl-dark; font-weight: 700; word-break: break-all;}

.h-status {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}

.h-collapse-toggle {
    @extend .row;
    margin-bottom: 0;
    .oi { top: 2px;}
    .btn-text { margin-left: .5rem;}
}

.display-1 {
    letter-spacing: -3px;
    abbr:not(:hover) { text-decoration: none;}
    sup {
        font-size: ($display1-size * .3);
        font-weight: 300;
        vertical-align: ($display1-size * .325);
        margin-left: .25rem;
        font-family: 'Roboto', sans-serif;
    }
}

.hanging-indent {
  padding-left: 1.6rem;
  text-indent: -1.6rem;
}

.section-docs {
    .h-display { font-size: ($display4-size * 1.2); letter-spacing: -1px;}
    h1:not(.h-display), .h1 { font-size: $display4-size; letter-spacing: -1px;}
    h2, .h2 { font-size: $h1-font-size;}
    h3, .h3 { font-size: $h1-font-size;}
    h4, .h4 { font-size: $h3-font-size;}
    h5, .h5 { font-size: $h4-font-size;}
    h6, .h6 { font-size: $font-size-base;}
}

label { font-weight: 500;}

.label-stacked {
    line-height: 1.2;
    padding: {top: 0;bottom: 0;}
}

legend:not(.legend-label) { font-size: $h3-font-size;}

.legend-label {
    @extend label;
    font-size: $font-size-base;
}

pre { white-space: pre-wrap;}
.pre-wrapper {
    border: 1px solid $smrtl-primary;
    border-radius: .25rem;
}

.hr-dark { border-top-color: $smrtl-dark;}

.dl-base {
    line-height: 1.2;
    margin-bottom: 0;
    dt { text-align: right;}
    dt:not(:last-of-type), dd:not(:last-of-type) { margin-bottom: .75rem;}
}

.dl-add-colon { dt::after { content: ": ";}}

.overlimit { @extend .text-danger;}

.svg-icon-wrapper { margin-right: .75rem; vertical-align: 1px; display: inline-block;}

.navbar {
    height: $fixed-nav-height;
    background-color: $smrtl-richblack;
    border-bottom: 4px solid $pb-2021-blue;
}

.pacb-cloud .navbar {
    background-color: $pb-2021-darker-blue;
    border-bottom: 4px solid $pb-2021-magenta;
}

.navbar-nav {
    align-items: center;
    & > .navbar-text:first-child { @media screen and (max-width: 639px) { display: none;}}
    .dropdown-menu { border-radius: 0;}
}

.nav-link {
    font-family: 'Merriweather Sans', $font-family-sans-serif;
    font-weight: 400;
    cursor: pointer;
}

.navbar-dark .navbar-nav .module-nav-menu .nav-link {
    color: $white;
    &:hover, &:focus { background-color: $smrtl-black;}
}

.pacb-cloud .navbar-dark .navbar-nav .module-nav-menu .nav-link {
    color: $white;
    &:hover, &:focus { background-color: $pb-2021-even-darker-blue;}
}

.module-nav-menu {
    .dropdown-menu {
        background-color: $pb-2021-darker-blue
    }
    .dropdown-item {
        color: $light;
        font-size: $font-size-lg;
        padding: {top: .5rem;bottom: .5rem;}
        &.active, &:active, &:hover, &:focus { color: $white; background-color: $pb-2021-blue}
        &:hover, &:focus { background-color: $pb-2021-hover-blue;}
    }
    .svg-icon-wrapper {
        width: 26px;
        height: 26px;
        border-radius: 30px;
        & + span { vertical-align: middle;}
        svg { vertical-align: top;}
    }
}

.module-nav-menu, .utility-nav-menu, .user-nav-menu {
    .nav-link { font-size: $font-size-base;}
}

.utility-nav-menu {
    & > a .oi { top: 2px;}
    .dropdown-menu {
        right: 0;
        left: auto;
    }
    .btn-link { @extend .dropdown-item;}
}

.home-module-nav {
    font-family: 'Merriweather Sans', $font-family-sans-serif;
    @include widthRem(326px);
}
.home-module-link {
    .svg-icon-wrapper {
        width: 50px;
        height: 50px;
        border-radius: 30px;
        & + span { vertical-align: middle;}
    }
    a {
        color: $white;
        font-size: $h1-font-size;
        font-weight: 500;
        &:hover, &:focus { text-decoration: underline !important; background-color: rgba(0,0,0,.1);}
    }
    &:not(:first-of-type) { margin-top: .5rem;}
}
.home-module-sublink {
    text-align: right;
    margin-top: -0.5rem;
    a { color: $smrtl-light; font-size: $font-size-lg;}
}

.nav-pills {
    &.flex-column .nav-item { margin-top: .5rem;}
    .nav-link {
        &.active:hover { color: $white;}
        &:not(.active):hover, &:not(.active):focus {
            background-color: $smrtl-accent-light;
        }
    }
}

.nav-submenu {
    .nav-link {
        color: $smrtl-dark;
        font-weight: 300;
        border-bottom: 3px solid transparent;
        .oi { font-size: .9rem;}
        &:not(.active):hover, &:not(.active):focus { background-color: $smrtl-accent-light;}
        &.active, &:active { border-color: $smrtl-dark;}
    }
}

.dropdown-header { font-size: $font-size-xs; text-transform: uppercase;}
.dropdown-item { font-size: $font-size-sm;}

.form-control:hover:not(:focus) {
    border-color: $input-hover-border-color;
}

input.form-control,
textarea.form-control {
    @include placeholder(){ font-style: italic;}
}

.form-control[readonly] {
    &:hover, &:focus { border-color: $input-border-color;}
    &:hover { cursor: default;}
}

.form-group.inactive {
    opacity: .7;
    input, select, textarea, button { cursor: not-allowed;}
}

.input-group-text { background-color: $white;}
.input-group-append .custom-select:last-child {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-append .btn-outline-primary {
    border-color: $input-border-color;
}

.input-group-radio-field, .input-group-radio-field-min {
    text-transform: uppercase;
    border-width: $input-border-width;
    border-style: solid;
    border-radius: $input-border-radius;
    label {
        font-family: "Helvetica Neue", Arial, Roboto, "Segoe UI", -apple-system, BlinkMacSystemFont, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 700;
    }
    .input-group-prepend { padding-right: 1.5rem;}
}

.input-group-radio-field {
    background-color: $body-bg;
    border-color: $body-bg;
    padding: $input-padding-y $input-padding-x;
}
.input-group-radio-field-min {
    background-color: transparent;
    border-color: transparent;
    padding: $input-padding-y 0;
}

.custom-input-group {
    .custom-select:last-child { max-width: 10rem;}
}

.btn-text {
    display: inline-block;
    & + .oi, & + .fa { margin-left: .25rem;}
}
.oi + .btn-text, .fa + .btn-text { margin-left: .25rem;}

.btn-icon {
    height: 100%;
    padding: {left: .75rem;right: .75rem;}
}

.td-btn {
    .btn-sm { font-size: .7rem; padding: 1px 5px;}
}

.breadcrumb-nav .breadcrumb {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
}

.table-sm tbody { font-size: .9rem;}

.modal-body { min-height: 200px;}

.modal-ag-grid {
    .modal-body { height: 50vh;}
}

.modal-ag-grid-tall {
    .modal-body { height: 65vh;}
}

.modal-body-ag-grid { height: 55vh;}

// This used to be an ag-grid native class.  Now we have to define it ourselves.
.ag-grid-highlighted { 
    background-color: rgba(33, 150, 243, .3); 
}

.no-min-height-grid .ag-center-cols-clipper {
    min-height: 40px !important;
}

.pb-ag-grid-edittable-cell {
    cursor: "text";
    width: 100%;
}
.pb-ag-grid-edittable-cell, textarea {
    background-color: #b3e6ff;
}

.pb-ag-grid-no-ellipsis {
    overflow: visible !important;
    white-space: normal !important;
    text-overflow: clip !important;
}

.popover {
    p { margin-top: .5rem;}
    .oi { font-size: $h3-font-size; margin-bottom: .5rem;}
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after { border-top-color: $popover-bg;}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after { border-right-color: $popover-bg;}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after { border-left-color: $popover-bg;}

// Body Theme

.btheme-base {
    .navbar { border-bottom-color: #657286;}
}

.btheme-welcome {
    background-color: $pb-2021-darker-blue;
    .navbar { border-bottom-color: $pb-2021-darker-blue;}
}

.pacb-cloud .btheme-welcome {
    background-color: white;
}

.btheme-rqc {
    .container-main { padding-bottom: 10rem;}
}

/* ===LAYOUT=== */

.welcome-content { min-height: 200px;}
.welcome-title {
    color: $white;
    .display-1 { font-weight: 400; font-size: 120px; line-height: 1; font-family: 'Roboto';}
    .display-2 { font-weight: 400; font-size: 50px; line-height: .9; font-family: 'Roboto';}
    height: 400px;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
}

.pacb-cloud .welcome-title {
    color: $black;
}

.welcome-navlink {
    font-family: 'Roboto', sans-serif;
}
.login-form {
    background-color: $white;
    padding: 1rem 1rem 1.5rem;
    border: #657286 3px solid;
    border-radius: .5rem;
}

.col-heading {
    font-size: $h2-font-size !important;
    padding-bottom: .25rem;
    border-bottom: 2px $smrtl-accent-gray solid;
}

.col-sub-heading {
    font-size: $h4-font-size !important;
    padding-bottom: .25rem;
}

.col-inner { min-height: 10vh; padding: .5rem 1rem 1rem;}

.col-inner, .col-detail-section { background-color: $smrtl-light;}

.col-detail-section {
    padding: {top: .25rem;bottom: .25rem;}
    border-radius: .5rem;
}

.collapse-inner, .table-wrapper, .pre-wrapper {
    background-color: rgba(255,255,255,.7);
    padding: 1.25rem 1.25rem 1rem;
    margin-bottom: .5rem;
    border-radius: .25rem;
}
.outer-grey-box {
    background-color:#efe9efbb;
}

/* ===SVG=== */

.fill-white { fill: $white;}
.fill-ssetup { fill: $smrtl-ssetup;}
.fill-rdesign { fill: $smrtl-rdesign;}
.fill-rqc { fill: $smrtl-rqc;}
.fill-dmgmt { fill: $smrtl-dmgmt;}
.fill-analysis { fill: $smrtl-analysis;}
.fill-info { fill: $info;}

.shift-left-8px {transform: translate(-8px, 0)}

/* ===COMPONENTS=== */

// Shared

.circle-badge {
    width: 18px;
    height: 18px;
    padding: 0;
    border-radius: 18px;
    vertical-align: text-top;
}
.circle-badge-inner {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    .oi, svg { position: absolute;}
    .oi { color: $white; left: 5px; z-index: 1;}
    svg { top: 1px; left: 1px;}
    &:hover .fill-info { fill: darken($info, 10%);}
}

.help-badge .oi { top: 2px;}
.info-badge .oi { top: 4px;}

.pb-link {
    color: $link-color;
    cursor: pointer;
}
.projects-dropdown {
    position: fixed;
    top: $fixed-nav-height;
    z-index: 10;
    @media screen and (max-width: 99em) {right: 1rem;}
    @media screen and (min-width: 100em) {right: 3rem;}
    @media screen and (min-width: 120em) {right: 5rem;}
    .dropdown-toggle {
        text-align: left;
        padding: {left: .75rem;right: .75rem;}
        border-radius: 0;
    }
}

.action-toolbar {
    align-items: center;
    justify-content: flex-end;
    .btn + .btn { margin-left: .5rem;}
}

.collapse-header {
    align-items: center;
    justify-content: flex-start;
    .btn + .btn { margin-left: .1rem;}
}

.collapse-header-label {
    font-size: 1.25em
}
.bs-single-search-select {
    .bs__control {
        border-color: $input-border-color;
        &:hover { border-color: $input-hover-border-color;}
        &.bs__control--is-focused {
            @extend .form-control, :focus;
            &:hover { border-color: $input-focus-border-color;}
        }
    }
    .bs__value-container { cursor: text;}
    .bs__clear-indicator { cursor: pointer;}
    .bs__placeholder {
        color: $input-placeholder-color;
        font-style: italic;
    }
    .bs__dropdown-indicator { cursor: pointer;}
    .bs__menu {
        z-index: 3;
        .bs__option {
            font-size: 1rem;
            &.bs__option--is-focused,
            &:hover { background-color: $dropdown-link-hover-bg;}
            &.bs__option--is-selected { background-color: $dropdown-link-active-bg;}
        }
    }
}

.input-group .bs-single-search-select {
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    .bs__control { border-top-right-radius: 0; border-bottom-right-radius: 0;}
}

.step-wizard {
    .breadcrumb {
        margin: 0;
        padding: 0;
        background-color: transparent;
        .breadcrumb-item {
            min-width: 9.375rem;
            background-color: $breadcrumb-bg;
            padding: .625rem 1rem .625rem 2rem;
            margin-bottom: 0;
            position: relative;
            display: block;
            float: left;
            &.active {
                color: $white;
                background-color: $smrtl-dark;
                &:first-child:after { border-left-color: $smrtl-dark;}
            }
            &:first-child {
                padding: {right: .625rem;left: 1rem;}
                border-top-left-radius: $breadcrumb-border-radius;
                border-bottom-left-radius: $breadcrumb-border-radius;
                &:not(.active):after { border-left-color: $breadcrumb-bg;}
            }
            &:last-child {
                padding: {right: 1.5rem;left: 2rem;}
                border-top-right-radius: $breadcrumb-border-radius;
                border-bottom-right-radius: $breadcrumb-border-radius;
            }
            &:before, &:after, & + li:before {
                content: '';
                color: transparent;
                padding: 0;
                display: block;
            }
            &:not(:last-child):after, &:not(:last-child):before {
                width: 0;
                height: 0;
                border-top: 1.3125rem solid transparent;
                border-bottom: 1.3125rem solid transparent;
                border-left-width: .875rem;
                border-left-style: solid;
                position: absolute;
                top: 50%;
                margin-top: -1.3125rem;
                left: 100%;
                z-index: 1;
            }
            &:not(:last-child):before { border-left-color: $white;}
            &:not(:last-child):after { border-left-color: $white; z-index: 3;}
        }
        .breadcrumb-item + .breadcrumb-item::before { display: none;}
    }
}

.data-cart-btn {
    font-size: 16px;
    margin-top: -10px;
    margin-right: 2rem;
    line-height: 1.2;
    position: relative;

    .oi {
        font-size: 1.3rem;
    }

    .badge {
        font-size: .8rem;
        position: absolute;
        top: 16px;
        left: 22px;
        background: var(--primary);
        color: #fff;
        border: 1px #fff solid;
    }
}

.btn-group-toggles-label {
    color: theme-color("dark");
    font-weight: 600;
    background-color: $white;
    border-color: $smrtl-dark;
    @extend .btn;
    &:hover, &:focus { color: $smrtl-dark; background-color: $white;}
}

.radio-tab-pills {
    .btn {
        @extend .nav-link;
        text-align: left;
        margin-top: .5rem;
        &:hover:not(.active), &:focus:not(.active) {
            background-color: $smrtl-accent-light;
        }
        &.active {
            color: $nav-pills-link-active-color;
            background-color: $nav-pills-link-active-bg;
        }
    }
}

.fig-card {
    background-color: #FFF;
    .card-body { padding: .25rem .5rem; text-align: center;}
    .card-title { font-size: $font-size-sm; margin-bottom: 0;}
    .card-img-bottom { background-color: $white;}
}
.fig-card-title-link {
    a:not([href]):not([tabindex]) { @extend .btn-link; cursor: pointer;}
    .modal-fig-detail {
        color: $link-color;
        cursor: pointer;
    }
    .btn-link {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        height: 100%;
        width: 100%;
        padding: 0;
    }
}

.sidebar {
    .card:not(:last-child) {
        .list-group-item:last-child { border-radius: 0;}
    }
    .card-title {
        margin-bottom: 0;
        .btn-link {
            font-size: 1.1rem;
        }
    }
    .list-group-item {
        text-align: right;
        padding: {top: .5rem;bottom: .5rem;}
        &.active {
            color: $white;
            background-color: $smrtl-dark;
            border-color: $smrtl-dark;
        }
        &:hover:not(.active), &:focus:not(.active) { background-color: $smrtl-accent-light;}
    }
}

.inner-fieldset-collapse {
    background-color: rgba(255,255,255,.8);
    padding: {top: 1rem;bottom: .25rem;}
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.plot-carousel-selected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    @media print {display: none;}
}
.plot-carousel-selected img {
    border: 1px solid lightgrey;
    max-height: 600px;
}

.plot-carousel-selected-thumbnail {
    border: 4px solid  #008EB9;
    @media print {
        border: unset;
    }
}

.plot-carousel-thumbnails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media print {
        display: block
    }
}

.plot-carousel-thumbnail {
    max-width: 15rem;
    padding-right: 10px;
    @media print {
        max-width: 50rem;
        border: unset;
        img {
            break-inside: avoid-page !important;
        }
    }
}

.plot-carousel-arrows {
    font-size: 40px !important;
    color: #008EB9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    @media print {
        display: none;
    }
}


// Run Design -- Well Picker


.selection-prompt {
    margin-top: 0.25rem;
    font-size: 1.25rem;
    text-align: center;
    font-weight: bold;
}

.selected-wells {
    margin-top: 0.5rem;
    font-size: 1.1rem;
}

.well-label, .well-empty, .well-allocated, .well-current, .well-unavailable {
    margin: 0.0625rem;
    width: 2rem;
    height: 2rem;
    line-height: 1.75rem;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    display: inline-block;
}

#wellPickerModal .modal-content {
    width: 32.15rem;
}

#wellPickerModal .well-picker-container {
    width: 30rem;
}

.well-empty, .well-allocated, .well-current, .well-unavailable {
    cursor: pointer;
    border-radius: 50%;
    border: 0.125rem solid black;
}

.well-empty {
    background: white;
}

.well-allocated {
    color: white;
    background: #483D8B;
}

.well-current {
    background: #00ffff;
}

.well-unavailable {
    background: grey;
}


/* ===MODULES=== */

.module-subhead {
    .svg-icon-wrapper { width: 32px; height: 32px;}
    .h1-text { display: inline-block; vertical-align: middle;}
}

.module-action-btn {
    @extend .btn;
    border-radius: .4rem;
    .btn-text { margin-left: .25rem;}
    .oi, .btn-text { vertical-align: middle;}
}

.rendered-file-browser-container, .rendered-folder-browser-container {
    background-color: $input-bg;
    border: 1px solid $input-border-color;
    border-radius: .25rem;
    padding: 1rem .5rem;
    div.rendered-react-keyed-file-browser div.files table {
        border-bottom: 2px solid $table-border-color;
        td { font-size: .9rem;}
        th.name i, td.name i {
            font-size: .9rem;
            &[class*='caret']{ vertical-align: -1px;}
        }
    }
}

.rendered-file-browser-container {
    div.rendered-react-keyed-file-browser div.files table {
        td.name { width: 50%; word-break: break-all;}
    }
}

.rendered-folder-browser-container {
    div.rendered-react-keyed-file-browser div.files table {
        td.size { display: none;}
    }
}

.rendered-file-browser-detail, .rendered-folder-browser-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// Sample Setup

.version-info { font-size: .9rem;}

.sample-section-top {
    div[class*="col"]:not(:first-child) { padding-left: 5px;}
    div[class*="col"] { padding-right: 5px;}
}

.sample-entry-container {
    background-color: lighten($smrtl-light, 2%);
    padding-bottom: 1rem;
    border-bottom: 1px solid $table-border-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-y: scroll;
}

.sample-entry-table  {
    table-layout: fixed;
    width: auto;
    margin: 0 auto;
    th, td { max-width: 19rem; min-width: 19rem; width: 19rem;}
    thead {
        th {
            font-size: .9rem;
            line-height: 1.5;
            text-align: center;
            padding: .5rem .9375rem;
            border-right-width: 0.0625rem;
            border-right-style: solid;
        }
        th:last-child { border-right-color: $smrtl-dark;}
    }
    tbody {
        th:not(.sample-warning), td:not(.sample-warning) { text-align: right;}
        th, td {
            line-height: 1.4;
            word-wrap: break-word;
            border-right: 0.0625rem solid $table-border-color;
            padding: .8125rem .9375rem;
            vertical-align: middle;
            overflow: hidden;
            &:first-child { border-left: 0.0625rem solid $table-border-color;}
        }
    }
    textarea { text-align: left; margin: {top: -0.5rem;bottom: -0.5rem;}}
    ul {
        font-size: .9rem;
        padding-left: 1.25rem;
        margin-bottom: 0;
        li { margin-bottom: 0.625rem;}
    }
    .units-container {
        display: flex;
        flex-direction: row;
        input[type="text"], .form-control[type="text"],
        select, select.custom-select {
            text-align: right;
            margin: { top: -0.3rem;bottom: -0.5rem;}
            display: inline;
            & + .label { margin-left: .625rem;}
        }
    }
    .input-group-radio-field-min { padding: 0; justify-content: flex-end;}
    .btn-link { padding: {top: 2px;bottom: 2px;}}
    tr.tr-toggle {
        th, td { padding: {top: .4rem;bottom: .4rem;}}
    }
}

.td-actions { .btn + .btn { margin-left: .25rem;}}
.tr-break-word { word-break: break-word;}

.sample-calc-sections {
    h1, .h1, p {
        margin: {right: 2rem;left: 2rem;}
        @media screen and (min-width: 100em) { margin: {right: 0;left: 0;}}
    }
    .units-container {
        @extend .d-print-none;
        width: 6.5rem;
        display: flex;
        flex-direction: row;
        input[type="text"] {
            background-color: rgba(#feff03, .3);
            border-color: $warning;
        }
    }
    textarea, input[type="text"], select {
        text-align: right;
        margin: {top: -0.3rem;bottom: -0.5rem;}
        display: inline;
    }
    label, .label {
        margin-left: 0.625rem;
    }
}

.calc-table-wrapper {
    padding-bottom: .25rem;
    overflow-y: scroll;
}

.sample-calc-table {
    font-size: .875rem;
    table-layout: fixed;
    width: auto;
    thead th { word-break: break-all; text-align: center;}
    tbody {
        th, td:not(.sample-warning) { vertical-align: middle; text-align: right;}
    }
    th, td { padding: .3rem .5rem;}
    &:not(.calc-4col-table) {
        th, td {
            &:first-child { max-width: 14rem; min-width: 14rem; width: 14rem;}
            &:not(.check-cell):not(:first-child) {
                max-width: 8rem;
                min-width: 8rem;
                width: 8rem;
            }
        }
        .check-cell { max-width: 3rem; min-width: 3rem; width: 3rem;}
        .notes { width: 100%;}
    }
    &.calc-4col-table {
        th, td {
            &:first-child { width: 25%;}
            &:not(.check-cell):not(.notes):not(:first-child) { width: 15%;}
        }
        .check-cell { width: 5%;}
        .notes { width: 55%;}
    }
    ul { font-size: .8rem; line-height: 1.3; padding-left: 1.1rem;}
    .line-above {
        th, td { border-top: 2px solid $smrtl-secondary;}
    }
    &.calc-inputs-table {
        tbody {
            th:first-child,
            td:first-child { height: 3.4375rem;}
        }
    }
    .final-warning { font-size: 8pt; line-height: 1.3;}
}

// Run Design

.run-design-sample-header {
    background-color: rgba(255,255,255,.8);
    border-radius: .25rem;
    padding: .5rem;
    display: flex;
    align-items: center;
    legend {
        @extend .font-weight-bold;
        @extend .text-truncate;
        @extend .mb-0;
        @extend .ml-2;
    }
}

.well-picker-container {
    min-height: 30vh;
    background-color: $smrtl-light;
    border: 1px solid $smrtl-accent-light;
    padding: 1rem;
    border-radius: .25rem;
    margin-bottom: .5rem;
}

// Run QC

.toggle-cell .btn-icon { width: 100%; border: none; padding: 0;}

.btn-cell-toggle {
    color: $smrtl-primary;
    &:hover { color: $smrtl-dark;}
}

.instrument-qc-table .ag-row .ag-cell {
    vertical-align: middle;
    display: grid;
    align-items: center;
    padding-top: 2px
}

.instruments-home-panel {
    background-color: rgba(206,216,220,0.6);
}
.instruments-home-panel dt {
    font-size: 1.1em;
    overflow: hidden;
}

.instruments-home-panel .collapse-inner {
    background-color: rgba(255,255,255,.9);
}

.instruments-home-panel-label {
    font-size: 1.2em
}
.instruments-home-panel-sub-label {
    font-size: 1.0em;
    &:hover { text-decoration: none !important;}
    vertical-align: middle;
    text-transform: uppercase;
}

.instruments-home-run-panel {
    background-color: rgba(220,220,220,.4);
    border: .5px solid white;
    border-radius: 0.5rem;
}


@media screen {

    .run-qc-sample-well-label {
        display: none;
    }

    .thumbnail-card {
        width: 15%;
        display:inline-block;
    }

    .thumbnail-card .card-footer {
        text-align: center;
        font-weight: bold;
    }
}

@media print {
    .expanding-well-list {
        display: block;
    }

    .run-qc-wells-plots-row {
        display: block;
    }

    .run-qc-well .list-group-item-heading {
        display: none;
    }

    .thumbnail-card {
        break-inside: avoid-page !important;
    }
}

.expanding-well-list {
    .card-columns {
        padding-top: 1rem;
        column-count: 7;
        grid-column-gap: .5rem;
        column-gap: .5rem;
    }
    span[class*="oi-chevron"] { font-size: .8rem;}
}

.status-label-complete {
    color: darkgreen;
}

.status-label-terminated {
    color: red;
}

.status-label-aborted {
    color: orange;
}

.status-label-running {
    color: blue;
}

.status-label-default {
    color: black;
}


// SMRT Analysis

.form-table-heading { font-size: $h3-font-size; font-weight: 500; display: block;}

.bottom_dark_border {
    border-bottom: 1px solid $smrtl-dark;
}

.fset-col-scroll {
    padding-right: 1rem;
    overflow-y: scroll;
}

.accordian-card-title {
    margin-bottom: .35rem;
    margin-top: .35rem;
}

.accordian-card-title:hover {
    cursor: pointer;
}

.accordian-section-item {
    text-align:right;
    &:hover:not(.active), &:focus:not(.active) { background-color: $smrtl-accent-light;}
}

.accordian-section-item:focus, accordian-section-item:active {
    outline: none !important;
    box-shadow: none;
 }

 .accordian-section-item:hover {
    cursor: pointer;
 }

 .accordian-section-item h4 {
     margin-bottom: 0.2rem !important;
     margin-top: 0.2rem !important;
 }

 .barcode-name-file-label-analysis {
    text-align: left;
    padding-right: 1.5rem !important;
 }

 .barcode-name-file-label-analysis {
    text-align: right;
    padding-right: 1.5rem !important;
 }

 // Data Management

.btn-toolbar-wide {
    @media screen and (max-width: 1180px) {
        margin: {left: -1.5rem;right: -1.5rem;}
        .custom-checkbox { font-size: .833rem; line-height: 1.9;}
    }
}

.srl-input-group { @include mwRem(130px);}

.col-ag-detail-view { height: 30vh; margin-bottom: .5rem;}

// mimics react-strap class ".input-group-sm > .form-control:not(textarea)" so that
// we can apply it to InputAddons as well
.input-sm-height-calculation {
    height: calc(1.5em + 0.5rem + 2px);
}

// Downloads table
.clickable-icon:active {
    color: green
}

.custom-control-input:disabled~.custom-control-label {
    color: #adadad !important;
}

// Forces horizontal scroll bar in ag-grid
.ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: scroll; overflow-y: scroll; }
    ::-webkit-scrollbar

    { -webkit-appearance: none; width: 8px; height: 8px; }
    ::-webkit-scrollbar-thumb

    { border-radius: 4px; background-color: rgba(0,0,0,.3); box-shadow: 0 0 1px rgba(255,255,255,.3); }

.analysis-file-input label {
    overflow:hidden
}

.rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}


.flat-mode-toggle {
    padding-left: 15px;
}

.flat-mode-toggle .toggle-not-active .oi {
    color: #BEBEBE !important;
}

.flat-mode-toggle .toggle-active .oi {
    color: black !important;
}

.flat-mode-toggle .toggle-not-active {
    background-color: white !important;
    color: grey;
    border: 1px solid lightgrey!important;
}

.flat-mode-toggle .toggle-active {
    background-color: lightblue !important;
    border: 1px solid #959595 !important;
}

.pg-settings-sub-section-title {
    font-size: 1.2em;
    font-weight:600;
}

// just a littler smaller than bootstraps "btn-sm"
.pb-small-button {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
    line-height: 1.2;
    border-radius: 0.15rem;
}

.pb-full-screen-loading-spinner {
    margin-top: 10rem;
    width: 12rem;
    height: 12rem;
    color: $pb-2021-magenta;
}

#pb-users-table .ag-center-cols-viewport {
    min-height: unset !important;
}
.pacb-cloud .dropdown-menu {
    background-color: white;
}

.pacb-cloud .dropdown-item {
    color: black !important;
}

.pacb-cloud .badge-danger {
    background-color: $pb-2021-dark-magenta;
}

.instrument-settings-table .ag-react-container {
    display: inline-flex
}

.pb-magenta {
    color: $pb-2021-magenta;
}

.pb-light-magenta {
    color: $pb-light-magenta;
}

.pb-light-teal {
    color: $pb-2021-light-teal
}

// Style the <CustomInput> label to match Chrome's placeholder class.
.custom-file-label {
    color: #363636 ;
    font-style: italic !important
}

.text-as-link {
    cursor: pointer;
    color: $link-color;
    &:hover { text-decoration: underline; }
}
