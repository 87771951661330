.centerview {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    min-width: min-content;
}

.default-unchanged input, .default-unchanged input.form-control, .default-unchanged select.form-control {
    background-color: palegreen
}

.default-changed input, .default-changed input.form-control, .default-changed select.form-control {
    background-color: yellow
}

.print-view {
    @media print { width: 100% !important; }
}

.print-view table {
    table-layout: fixed;
    break-inside: avoid-page;
}

.instructions-ht .print-view table {
    width: 100%;
}

.print-view table.SampleDetailsTable {
    break-inside: auto;
}

.print-view thead {
    height: 2.5rem;
    background-color: #6b7780;
    display: table-row-group;
}

.print-view thead th {
    color: #fff;
    border-right: 0.0625rem solid #fff;
    font-weight: bold;
    text-align: center;
}

.print-view thead th:first-child {
    border-left: 0.0625rem solid #5b6770;
}

.print-view thead th:last-child {
    border-right-color: #5b6770;
}

.print-view tbody tr {
    border-top: 1px solid #b1b8bd;
}

.print-view tbody tr:last-child {
    border-bottom: 1px solid #b1b8bd;
}

.print-view tbody tr:nth-child(even) {
	background-color: #EDEDED;
}

.print-view tbody tbody tr:nth-child(2n+1) {
	background-color: white;
}

.print-view .steps tbody tr {
	border: none
}

.print-view .steps tbody td {
	border: none
}

.print-view tbody tr.lineabove {
    border-top: 0.075rem solid rgb(51,51,51);
    font-weight: 500;
}

.print-view td {
    border-right: 0.0625rem solid #b1b8bd;
    color: black;
    line-height: 1.2;
    max-width: 8rem;
    min-width: 8rem;
    width: 8rem;
    overflow: hidden;
    word-wrap:break-word;
    padding: 0.8125rem 0.9375rem;
    text-align: right;
}

.print-view .steps td {
    padding: 0.5rem 0.5rem;
}

.print-view td.tube {
    padding: 0;
}

.print-view .SampleDetailsTableHT td {
    min-width: 14rem;
    width: 14rem;
}

.print-view .notes {
    width: 100%;
}

.instructions-classic .print-view td:first-child {
    max-width: 14rem;
    min-width: 14rem;
    width: 14rem;
}

.instructions-ht .print-view table:not(.SampleDetailsTable) td:nth-child(1) {
    max-width: 3rem;
    min-width: 3rem;
    width: 3rem;
}

.instructions-ht .print-view table:not(.SampleDetailsTable) td:nth-child(2) {
    max-width: 6rem;
    min-width: 6rem;
    width: 6rem;
}

.instructions-ht .print-view table:not(.SampleDetailsTable) td:nth-child(3) {
    min-width: 24rem;
    width: 24rem;
}

.print-view td.sample-warning {
    text-align: left;
}

.print-view td:first-child {
    border-left: 0.0625rem solid #b1b8bd;
}

.print-view ul {
    padding-left: 1.25rem;
    margin-bottom: 0;
}

.print-view li {
    margin-bottom: 0.625rem;
}

.print-view li:last-child {
    margin-bottom: 0;
}

.print-view h1 {
	font-size: 4.25rem;
	line-height: 4.5rem;
	letter-spacing: -0.063rem;
}

.print-view h2 {
    padding-top: 2rem;
	font-size: 2.0rem;
	line-height: 3rem;
}

.print-view h3 {
	font-size: 1.75rem;
	line-height: 2.5rem;
    margin-top: 1rem;
}

.print-view h4 {
    padding-top: 1rem;
}

.print-view p {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 31.25rem;
}

.print-view .BindingPolymeraseTable {
    margin-top: 1rem;
}

.print-view table {
    margin-top: 0.5rem;
    margin-bottom: 1.0rem;
}

.print-view .steps table {
    margin-top: 0.5rem;
    margin-bottom: 0.0rem;
}

.print-view td.multiline {
    line-height: 1.3rem;
}

.print-view td.table-instruction {
    width: 100%;
    text-align: left;
}

/* next five definitions are for column cleanup */

.print-view .units-container {
    display: flex;
    width: 6.5rem;
    flex-direction: row;
}

.print-view label {
    color: inherit;
    font: inherit;
    margin-left: 0.625rem;
}

.print-view .column-cleanup-error {
    color: red;
}

.print-view .insufficient-sample-volume-warning {
    color: red;
}

.print-view textarea,
.print-view input[type="text"],
.print-view select {
	display: inline;
    /* CSS validation complains about these next two lines being ignored but they are used */
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;
    text-align: right;
}

.print-view input[type="text"] {
    background-color: yellow;
}

.print-view input[type="text"][disabled] {
    background-color: grey;
}

/* Chrome unfortunately seems to ignore this styling for the select, so styling entire table cell */
.print-view select.column-cleanup-select option,
.print-view td.column-cleanup-td {
    background-color: yellow;
}

.print-span {
    display: none;
}

@media print {
    :host {
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 2rem;
    }

    .print-view thead th {
        border: 0.0625rem solid black;
    }

    .print-view .potential_page_break {
        display: block;
        page-break-before: always;
    }

    /* narrow columns for printing */

    .print-view td {
        border-right: 0.0625rem solid #b1b8bd;
        line-height: 1.2;
        padding: 0.5rem 0.6rem;
        max-width: 6rem;
        min-width: 6rem;
        width: 6rem;
    }

    .print-view td:first-child {
        max-width: 9rem;
        min-width: 9rem;
        width: 9rem;
    }

    .print-view .units-container {
        display: none;
    }

    .print-view .print-span {
        display: inline;
    }

    .container-fluid {
        margin-left: 0;
        margin-right: 0;
    }

    .print-view h3 {
        width: 100%
    }
}
